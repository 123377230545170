import React, { Fragment, useRef } from 'react';
import Slant from '../type/slant';
import { useShot } from './hooks';
import { AttemptStep, Position, ShootoutProps } from './types';
import { getVideoSrc } from './util';
import { SaveResult, ScoreResult } from './result';
import logo from '../../logo-simple.svg';
import goal from '../../goal.jpg';

export { useShootout } from './hooks';

export function Shot(props: ShootoutProps) {
    const { isScore } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isCurrent, setCurrent] = useShot();

    function handleClick(position: Position) {
        return () => {
            setCurrent(AttemptStep.VIDEO);

            if (videoRef.current) {
                videoRef.current.src = getVideoSrc(position, isScore);
                videoRef.current.play().catch(error => {
                    console.error(error);
                });
            }
        };
    }

    function handleVideoEnded() {
        setCurrent(AttemptStep.RESULT);
    }

    return (
        <Fragment>
            <div className='grid relative bg-center bg-cover' style={{ backgroundImage: `url('${goal}')` }}>
                <div className='z-10 col-span-full row-span-full'>
                    <video ref={videoRef} onEnded={handleVideoEnded} className='block w-full' />
                </div>
                {isCurrent(AttemptStep.RESULT) && (
                    <div className='z-20 col-span-full row-span-full flex items-center justify-center bg-black bg-opacity-75'>
                        {isScore
                            ? <ScoreResult {...props} />
                            : <SaveResult {...props} />}
                    </div>
                )}
                {isCurrent(AttemptStep.CHOOSE) && (
                    <div className='z-20 col-span-full row-span-full grid grid-cols-2 grid-rows-2 bg-black bg-opacity-50'>
                        <div className='flex items-center justify-center col-start-1'>
                            <ShootButton onClick={handleClick(Position.TOP_LEFT)}>
                                Top left
                            </ShootButton>
                        </div>
                        <div className='flex items-center justify-center col-start-2'>
                            <ShootButton onClick={handleClick(Position.TOP_RIGHT)}>
                                Top right
                            </ShootButton>
                        </div>
                        <div className='flex items-center justify-center col-start-1'>
                            <ShootButton onClick={handleClick(Position.BOTTOM_LEFT)}>
                                Bottom left
                            </ShootButton>
                        </div>
                        <div className='flex items-center justify-center col-start-2'>
                            <ShootButton onClick={handleClick(Position.BOTTOM_RIGHT)}>
                                Bottom right
                            </ShootButton>
                        </div>
                    </div>
                )}
                <div className='absolute top-0 left-0 z-40 pt-8 pl-8'>
                    <img src={logo} alt='' className='w-16 opacity-50' />
                </div>
            </div>
        </Fragment>
    );
}

function ShootButton({ onClick, children }: ShootButtonProps) {
    return (
        <button type='button' onClick={onClick} className='w-64 border-2 border-white p-4 font-heading text-4xl uppercase'>
            <Slant>{children}</Slant>
        </button>
    );
}

type ShootButtonProps = {
    onClick: () => void,
    children: string,
};
