import React, { Fragment } from 'react';
import DetailsForm from '../components/form/details';
import logo from '../logo.svg';

export default function Details() {
    return (
        <Fragment>
            <div className='flex flex-col items-center px-16 py-12 text-center'>
                <img src={logo} alt='Grant’s' className='w-32' />
            </div>
            <div className='px-10 pt-16 pb-20'>
                <div className='max-w-xl mx-auto'>
                    <DetailsForm />
                </div>
            </div>
        </Fragment>
    );
}
