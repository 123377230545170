import React, { Fragment } from 'react';

export default function Slant({ children }: SlantProps) {
    return (
        <Fragment>
            {children.split(/\s+/).map((word, i) => (
                <Fragment key={i}>
                    <span className='inline-block slant skew-x-[-9deg]'>{word}</span>
                    {' '}
                </Fragment>
            ))}
        </Fragment>
    );
}

type SlantProps = {
    children: string,
};
