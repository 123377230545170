import { Position } from './types';

export function getVideoSrc(position: Position, score: boolean) {
    return `https://grants-triple-chance.s3.eu-west-2.amazonaws.com/${position}-${score ? 'goal' : 'save'}.mp4`;
}

export function getResults(chances: number) {
    return [true, false, true];

    const goals = getRandomInt(1, chances);
    const results: boolean[] = [];

    for (let i = 0; i < chances; i++) {
        results.push(i < goals);
    }

    return results;
}

export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1) + min);
}
