export type ShootoutProps = {
    shot: number,
    isScore: boolean,
    score: number,
    next: () => void,
    isEnd: boolean,
};

export enum AttemptStep {
    CHOOSE,
    VIDEO,
    RESULT,
}

export enum Position {
    TOP_LEFT = 'tl',
    TOP_RIGHT = 'tr',
    BOTTOM_LEFT = 'bl',
    BOTTOM_RIGHT = 'br',
}
