import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Play from './pages/play';
import Details from './pages/details';

export default function App() {
    return (
        <div className='max-w-7xl mx-auto'>
            <Router>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/play' element={<Play />} />
                    <Route path='/details' element={<Details />} />
                </Routes>
            </Router>
        </div>
    );
}
