import React from 'react';
import { Link } from 'react-router-dom';
import { ShootoutProps } from './types';
import Slant from '../type/slant';

function Result({ heading, children, next, isEnd }: React.PropsWithChildren<ResultProps>) {
    return (
        <div className='px-10 pt-16 pb-20 text-center'>
            <h2 className='mb-10 font-heading text-8xl uppercase'>
                <Slant>{heading}</Slant>
            </h2>
            <div className='mb-12 space-y-4 text-2xl'>
                {children}
            </div>
            {isEnd
                ? (
                    <Link to='/details' className='inline-block px-12 py-5 rounded-md bg-red font-heading text-4xl uppercase'>
                        <Slant>Enter your details</Slant>
                    </Link>
                ) : (
                    <button onClick={next} className='inline-block px-12 py-5 rounded-md bg-red font-heading text-4xl uppercase'>
                        <Slant>Take your next shot!</Slant>
                    </button>
                )}
        </div>
    );
}

type ResultProps = ShootoutProps & {
    heading: string,
};

export function ScoreResult(props: ShootoutProps) {
    const { score, isEnd } = props;

    return (
        <Result heading='Goal!' {...props}>
            <p>You’ve received {score > 1 ? 'another' : 'one'} entry into our promotion to win [insert prize here].</p>
            {!isEnd && (
                <p>Try to score another goal, to gain an extra entry…</p>
            )}
        </Result>
    );
}

export function SaveResult(props: ShootoutProps) {
    const { isEnd } = props;

    return (
        <Result heading='Oooh!' {...props}>
            <p>Unlucky! Your shot was saved by the keeper! {!isEnd && 'Try again…'}</p>
        </Result>
    );
}
