import React, { Fragment } from 'react';
import { Shot, useShootout } from '../components/shootout';

export default function Play() {
    const shootout = useShootout(3);
    const { shot } = shootout;

    return (
        <Fragment>
            <Shot key={shot} {...shootout} />
        </Fragment>
    );
}
