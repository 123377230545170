import React, { Fragment } from 'react';
import Slant from '../components/type/slant';
import logo from '../logo.svg';
import { Link } from 'react-router-dom';
import goal from '../goal.jpg';

export default function Home() {
    return (
        <Fragment>
            <div className='flex flex-col items-center px-16 py-24 text-center bg-black bg-opacity-25 bg-center bg-cover' style={{ backgroundImage: `url('${goal}')` }}>
                <img src={logo} alt='Grant’s' className='w-48 mb-12' />
                <h1 className='mb-5 flex flex-col font-heading text-8xl uppercase'>
                    <span>
                        <Slant>Triple Chance</Slant>
                    </span>
                    <span className='text-red'>
                        <Slant>Penalty Shoot Out</Slant>
                    </span>
                </h1>
                <p className='font-heading text-4xl uppercase'>
                    <Slant>One game, three chances to win!</Slant>
                </p>
            </div>
            <div className='px-10 pt-16 pb-20 text-center'>
                <div className='max-w-xl mx-auto mb-12 space-y-4 text-2xl'>
                    <p>Have you got what it takes to hit the back of the net? You’ve 3 penalties to try your luck!</p>
                    <p>Every goal you score is an entry into our promotion to win [insert prize here]!</p>
                    <p>
                        Step up…<br />
                        Pick your spot…<br />
                        Try to beat the keeper!
                    </p>
                </div>
                <Link to='/play' className='inline-block px-12 py-5 rounded-md bg-red font-heading text-4xl uppercase'>
                    <Slant>Play now</Slant>
                </Link>
            </div>
        </Fragment>
    );
}
