import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Slant from '../type/slant';

export default function DetailsForm() {
    const initialValues: DetailsFormValues = {
        firstName: '',
        lastName: '',
        email: {
            first: '',
            second: '',
        },
        agreeTerms: false,
        optIn: false,
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter your first name'),
        lastName: Yup.string()
            .required('Please enter your surname'),
        email: Yup.object().shape({
            first: Yup.string()
                .required('Please enter your email address')
                .email('Please enter a valid email address'),
            second: Yup.string()
                .required('Please enter your email address')
                .email('Please enter a valid email address'),
        }),
        agreeTerms: Yup.boolean()
            .required('Please accept the terms and conditions')
    });

    async function handleSubmit(values: DetailsFormValues) {
        console.log(values);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <Form>
                <DetailsFormFields />
            </Form>
        </Formik>
    );
}

type DetailsFormValues = {
    firstName: string,
    lastName: string,
    email: {
        first: string,
        second: string,
    },
    agreeTerms: boolean,
    optIn: boolean,
};

function DetailsFormFields() {
    return (
        <div>
            <h2 className='mb-10 font-heading text-5xl uppercase text-center'>
                <Slant>Enter your details</Slant>
            </h2>
            <div className='mb-10 space-y-5'>
                <div className='space-y-4'>
                    <Field
                        name='firstName'
                        placeholder='First name'
                        className='border-0 w-full px-6 py-4 rounded-md text-2xl text-black focus:ring-4 focus:ring-red focus:outline-none'
                    />
                </div>
                <div className='space-y-4'>
                    <Field
                        name='lastName'
                        placeholder='Surname'
                        className='border-0 w-full px-6 py-4 rounded-md text-2xl text-black focus:ring-4 focus:ring-red focus:outline-none'
                    />
                </div>
                <div className='space-y-4'>
                    <Field
                        type='email'
                        name='email.first'
                        placeholder='Email address'
                        className='border-0 w-full px-6 py-4 rounded-md text-2xl text-black focus:ring-4 focus:ring-red focus:outline-none'
                    />
                </div>
                <div className='space-y-4'>
                    <Field
                        type='email'
                        name='email.second'
                        placeholder='Confirm email address'
                        className='border-0 w-full px-6 py-4 rounded-md text-2xl text-black focus:ring-4 focus:ring-red focus:outline-none'
                    />
                </div>
            </div>
            <div className='space-y-3'>
                <label className='flex'>
                    <span className='flex-shrink-0'>
                        <Field type='checkbox' name='agreeTerms' className='border-0 rounded-sm w-6 h-6 text-red' />
                    </span>
                    <span className='flex-1 ml-4 pt-0.5'>
                        I confirm that I am over 18 and have read and agree to the <a href='' className='underline'>Terms&nbsp;&&nbsp;Conditions</a>
                    </span>
                </label>
                <label className='flex'>
                    <span className='flex-shrink-0'>
                        <Field type='checkbox' name='optIn' className='border-0 rounded-sm w-6 h-6 text-red' />
                    </span>
                    <span className='flex-1 ml-4 pt-0.5'>
                        I would like to receive exciting news and information from Grant’s
                    </span>
                </label>
            </div>
            <div className='mt-20 text-center'>
                <button type='submit' className='inline-block px-12 py-5 rounded-md bg-red font-heading text-4xl uppercase'>
                    <Slant>Submit your details</Slant>
                </button>
            </div>
        </div>
    );
}
