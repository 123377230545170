import { useMemo, useState, useEffect } from 'react';
import { AttemptStep, ShootoutProps } from './types';
import { getResults } from './util';

export function useShootout(chances: number): ShootoutProps {
    const [shot, setShot] = useState(0);
    const [score, setScore] = useState(0);
    const results = useMemo(() => getResults(chances), [chances]);
    const isScore = results[shot];
    const isEnd = shot === chances - 1;

    useEffect(() => {
        if (isScore) {
            setScore(score => score + 1);
        }
    }, [shot, isScore]);

    function next() {
        if (isEnd) {
            throw new Error();
        }

        setShot(shot => shot + 1);
    }

    return {
        shot,
        isScore,
        score,
        next,
        isEnd,
    };
}

export function useShot() {
    const [current, setCurrent] = useState(AttemptStep.CHOOSE);
    const isCurrent = (step: AttemptStep) => step === current;

    return [isCurrent, setCurrent] as const;
}
